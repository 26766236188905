.home-container {
    max-width: 900px;
    /* margin: 40px auto; */
    text-align: left;
}

/* Center the entire white box */
.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height */
    background-color: #f4f4f4; /* Light grey background */
}

.greeting {
    font-size: 3.2em;
    color: #333;
    margin-bottom: 20px;
}

.intro {
    font-size: 1.4em;
    color: #555;
    line-height: 1.8;
    margin-bottom: 40px;
}

/* Projects Section */
.projects-preview, .blogs-preview {
    margin-top: 50px;
}

.projects-preview h2, .blogs-preview h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 30px;
}

.projects-list, .blogs-list {
    display: flex;
    gap: 20px;
}

.project-card, .blog-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.project-card h3, .blog-card h3 {
    font-size: 1.5em;
    color: #333;
}

.project-card p, .blog-card p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 15px;
}

.project-card a, .blog-card a {
    text-decoration: none;
    color: #007BFF;
    font-weight: 500;
}

.project-card a:hover, .blog-card a:hover {
    color: #0056b3;
}

/* Social Links */
.social-links {
    margin-top: 50px;
}

.social-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #333;
    font-size: 1.3em;
}

.social-links a:hover {
    color: #007BFF;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .projects-list, .blogs-list {
        flex-direction: column;
    }

    .project-card, .blog-card {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .greeting {
        font-size: 2.5em;
    }

    .intro {
        font-size: 1.3em;
    }
    
    /* Add padding for mobile view */
    .home-container {
        padding: 20px; /* Add padding on mobile */
    }

    .project-card, .blog-card {
        padding: 15px; /* Slightly reduce padding on smaller screens */
    }
}
