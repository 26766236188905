.navbar {
    background-color: transparent;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
}

.navbar h1 {
    color: #333;
    font-size: 1.8em;
    margin: 0;
}

.nav-links {
    display: flex;
    flex-wrap: nowrap; /* Ensure items stay in a single line */
}

.nav-links a {
    color: #555;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.2em;
    font-weight: 500;
}

.nav-links a:hover {
    color: #000;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .nav-links a {
        margin: 0 10px;
        font-size: 1.1em;
    }

    .navbar h1 {
        font-size: 1.5em;
    }
}

/* Media query for phones */
@media (max-width: 480px) {
    .navbar {
        flex-direction: column; /* Stack logo and links vertically */
        align-items: flex-start; /* Align items to the left */
    }

    .nav-links {
        display: flex;
        justify-content: space-between; /* Keep items in a row */
        width: 100%; /* Ensure it spans the full width */
    }

    .nav-links a {
        margin: 0 5px;
        font-size: 1em;
    }

    .navbar h1 {
        font-size: 1.3em;
        margin-bottom: 10px; /* Add spacing below the logo */
    }
}
