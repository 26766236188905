@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:wght@300;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 20px;
}

h1, h2 {
  font-weight: 600;
  color: #333;
}

p, ul {
  color: #555;
  line-height: 1.8;
  font-size: 1.4em;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  h1 {
    font-size: 2.4em;
  }

  p {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1.1em;
  }

  .container {
    padding: 10px;
  }
}
