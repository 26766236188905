.blog {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
  }
  
  .blog h2 {
    color: #333;
    font-size: 2.2em;
    margin-bottom: 20px;
  }
  
  .blog ul {
    list-style-type: none;
    padding: 0;
  }
  
  .blog ul li {
    font-size: 1.2em;
    color: #555;
    background-color: #f5f5f5;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .blog ul li:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .blog {
      padding: 15px;
    }
  
    .blog h2 {
      font-size: 1.8em;
    }
  
    .blog ul li {
      font-size: 1em;
      padding: 12px;
    }
  }
  